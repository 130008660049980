import React, { useState, useEffect } from 'react';
import StateServices from '../../services/StateServices.js';

function UpdateState({ selectedData, closeModal, onSuccess }) {
  const [formValues, setFormValues] = useState({
    name: '',
  });

  useEffect(() => {
    if (selectedData) {
      setFormValues({
        name: selectedData.name || '',
      });
    }
  }, [selectedData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
    setFormValues({
      ...formValues,
      [name]: capitalizedValue,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const res = await StateServices.updateState(selectedData._id, { name: formValues.name });
      if (res.status === true) {
        alert('State updated successfully');
        onSuccess();
        closeModal();
      } else {
        alert('Something went wrong');
      }
    } catch (error) {
      console.error('Failed to update state', error);
      alert(error.response?.data?.message || 'Failed to update state');
    }
  };

  return (
    <div className="modal fade edit-box show d-block" id="editModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Edit State</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={closeModal} aria-label="Close" />
          </div>
          <div className="modal-body px-5 pb-0">
            <div className="container-box px-5">
              <div className="container-box-inner">
                <div className="page-details">
                  <form onSubmit={handleSubmit}>
                    <div className="input-field">
                      <label>State Name*</label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        value={formValues.name}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <button className="submit-green-btn" type="submit">
                      SUBMIT
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateState;
