import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import StateServices from '../../services/StateServices.js';


function AddDistrict() {
  const [formValues, setFormValues] = useState({ 
    name: '', 
    state: '',
    code: ''
  });
  const [states, setStates] = useState([]);

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const res = await StateServices.getState();
        if (res.status === true) {
            setStates(res.data);
        } else {
          alert('Failed to fetch states');
        }
      } catch (error) {
        console.error('Failed to fetch states', error);
        alert('Failed to fetch states');
      }
    };

    fetchStates();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
    setFormValues({
      ...formValues,
      [name]: capitalizedValue,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const res = await StateServices.createDistrict({ name: formValues.name, state: formValues.state,code: formValues.code });
      console.log(res);
      if (res.status === true) {
        alert('District added successfully');
        setFormValues({ name: '', state: '', code:'' });
      } else {
        alert('Something went wrong');
      }
    } catch (error) {
      console.error('Failed to add district', error);
      alert(error.response?.data?.message || 'Failed to add district');
     
    }
  };

  return (
    <div className="right_col" role="main">
      <div className="title-box">
        <h2>District Add</h2>
        <div className="container-box-top-header-right">
          <Link className="list-banner-btn" to="/district">District List</Link>
        </div>
      </div>
      <div className="container-box px-5">
        <div className="container-box-inner">
          <div className="page-details">
            <form onSubmit={handleSubmit}>
            <div className="input-field">
                <label>State Name*</label>
                <select
                  name="state"
                  className="form-control"
                  value={formValues.state}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select a state</option>
                  {states.map((state) => (
                    <option key={state._id} value={state._id}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input-field">
                <label>District Name*</label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  value={formValues.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="input-field">
                <label>District Code</label>
                <input
                  type="text"
                  name="code"
                  className="form-control"
                  value={formValues.code}
                  onChange={handleInputChange}
               
                />
              </div>
              
              <button className="submit-green-btn" type="submit">
                SUBMIT
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddDistrict;
