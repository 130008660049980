import React from 'react';

function ViewUser({ user, closeModal }) {
  if (!user) return null;

  function formatDateTime(isoString) {
    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return { date: formattedDate, time: formattedTime };
  }

  const { date, time } = formatDateTime(user.createdAt);

  // Function to handle print
  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="modal fade viewbox edit-box show d-block" id="manufacturerModal" tabIndex={-1} aria-labelledby="manufacturerModal" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="manufacturerModal">User Details</h5>
            <button type="button" className="btn-close" onClick={closeModal} aria-label="Close"></button>
          </div>
          <div className="modal-body px-5">
            <div className="d-box">
              <div className="d-flex justify-content-end align-items-center">
                <img src={user?.image ? `${process.env.REACT_APP_URL}${user?.image}` : 'img/profile-img.png'} alt="" style={{ height: '90px', width: '90px', objectFit: 'contain', marginRight: '100px', marginBottom: '20px' }} />
              </div>

              {/* Basic User Information */}
              <ul className="d-flex list-unstyled flex-wrap justify-content-between">
                <li><span>Name:</span> {user.name || 'N/A'}</li>
                <li><span>Age:</span> {user.age || 'N/A'}</li>
                <li><span>Father's Name:</span> {user.fathersName || 'N/A'}</li>
                <li><span>Mobile No:</span> {user.mobileNo || 'N/A'}</li>
                <li><span>Gender:</span> {user.gender || 'N/A'}</li>
                <li><span>Aadhar No:</span> {user.aadharNo || 'N/A'}</li>
              </ul>

              {/* Present Address */}
              <h6><span>Present Address:</span></h6>
              <ul className="d-flex list-unstyled flex-wrap justify-content-between">
                <li><span>Mohalla:</span> {user?.presentAddress?.mohalla || 'N/A'}</li>
                <li><span>Ward:</span> {user?.presentAddress?.ward || 'N/A'}</li>
                <li><span>Village:</span> {user?.presentAddress?.village || 'N/A'}</li>
                <li><span>Police Station:</span> {user?.presentAddress?.policeStation?.name || 'N/A'}</li>
                <li><span>District:</span> {user?.presentAddress?.district?.name || 'N/A'}</li>
                <li><span>State:</span> {user?.presentAddress?.state?.name || 'N/A'}</li>
                <li><span>Pincode:</span> {user?.presentAddress?.pinCode || 'N/A'}</li>
              </ul>

              {/* Permanent Address */}
              <h6><span>Permanent Address:</span></h6>
              <ul className="d-flex list-unstyled flex-wrap justify-content-between">
                <li><span>Mohalla:</span> {user?.permanentAddress?.mohalla || 'N/A'}</li>
                <li><span>Ward:</span> {user?.permanentAddress?.ward || 'N/A'}</li>
                <li><span>Village:</span> {user?.permanentAddress?.village || 'N/A'}</li>
                <li><span>Police Station:</span> {user?.permanentAddress?.policeStation?.name || 'N/A'}</li>
                <li><span>District:</span> {user?.permanentAddress?.district?.name || 'N/A'}</li>
                <li><span>State:</span> {user?.permanentAddress?.state?.name || 'N/A'}</li>
                <li><span>Pincode:</span> {user?.permanentAddress?.pinCode || 'N/A'}</li>
              </ul>

              {/* Profession Details */}
              <ul className="d-flex list-unstyled flex-wrap justify-content-between">
                <li><span>Profession:</span> {user?.profession?.profession || 'N/A'}</li>
                <li><span>Name of Shop:</span> {user?.profession?.nameOfShop || 'N/A'}</li>
                <li><span>Owner Name:</span> {user?.profession?.ownerName || 'N/A'}</li>
                <li><span>Address:</span> {user?.profession?.address || 'N/A'}</li>
                <li><span>Mobile No:</span> {user?.profession?.mobileNo || 'N/A'}</li>
                <li><span>Vehicle No:</span> {user?.profession?.vehicleNo || 'N/A'}</li>
              </ul>

              {/* Family Members */}
              <h6><span>Family Members:</span></h6>
              <ul className="list-unstyled">
                {user.familyMembers?.length > 0 ? (
                  user.familyMembers.map((member) => (
                    <li key={member._id}>
                      <ul className="d-flex list-unstyled flex-wrap justify-content-between">
                        <li><span>Name:</span> {member.name || 'N/A'}</li>
                        <li><span>Relation:</span> {member.relation || 'N/A'}</li>
                        <li><span>Age:</span> {member.age || 'N/A'}</li>
                        <li><span>Aadhar No:</span> {member.aadhar || 'N/A'}</li>
                        <li><span>Mobile No:</span> {member.mobileNo || 'N/A'}</li>
                      </ul>
                    </li>
                  ))
                ) : (
                  <li>N/A</li>
                )}
              </ul>

              {/* Verification ID */}
              <ul className="d-flex list-unstyled flex-wrap justify-content-between">
                <li>
                  <span>Verification Id:</span>
                  {user?.presentAddress?.policeStation?.code && user?.verificationId && user?.presentAddress?.district?.code
                    ? `${user.presentAddress.policeStation.code}${user.verificationId}${user.presentAddress.district.code}`
                    : user?.presentAddress?.policeStation?.code || user?.verificationId || user?.presentAddress?.district?.code
                      ? `${user.presentAddress.policeStation?.code || ''}${user.verificationId || ''}${user.presentAddress.district?.code || ''}`.trim()
                      : 'N/A'}
                </li>

              </ul>

              {/* ID Type */}
              <ul className="d-flex list-unstyled flex-wrap justify-content-between">
                <li><span>ID Type:</span> {user?.idType || 'N/A'}</li>
              </ul>

              {/* User Images */}
              {user.userType !== 'customer' && (
                <div className="mt-5 d-box-grp">
                  <a href={process.env.REACT_APP_URL + user?.idPhoto?.front} target='_blank' rel="noopener noreferrer">
                    <img src={user?.idPhoto?.front ? `${process.env.REACT_APP_URL}${user?.idPhoto?.front}` : 'img/profile-img.png'} alt="" style={{ height: '200px', width: '200px', objectFit: 'contain', marginRight: '100px', marginBottom: '20px' }} />
                  </a>
                  <a href={process.env.REACT_APP_URL + user?.idPhoto?.back} target='_blank' rel="noopener noreferrer">
                    <img src={user?.idPhoto?.back ? `${process.env.REACT_APP_URL}${user?.idPhoto?.back}` : 'img/profile-img.png'} alt="" style={{ height: '200px', width: '200px', objectFit: 'contain', marginRight: '100px', marginBottom: '20px' }} />
                  </a>

                  {/* <a href={process.env.REACT_APP_URL + user?.signatureFinger} target='_blank' rel="noopener noreferrer">
                    <button className="document-update-btn"><img src="img/pdf.svg" alt="PDF" /> Signature</button>
                  </a> */}
                </div>
              )}

              {/* Print Button */}
              <div className="text-center mt-4">
                <button className="btn btn-primary" onClick={handlePrint}>Print</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewUser;
