import requests from "./httpService";

const FaqServices = {

    AddFaq : async (body) =>{
        return requests.post(`/faq`,body);
    },

    
   

    getAllFaq : async () =>{
        return requests.get(`/faq`);
    },

    updateFaq : async (id,body) =>{
        return requests.patch(`/faq/${id}`,body);
    },
    deleteFaq : async (id) =>{
        return requests.delete(`/faq/${id}`);
    }
}

export default FaqServices;