import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './Css/sideBar.css';
import state from '../Header/Img/state.png';
import district from '../Header/Img/district.png';
import thana from '../Header/Img/police.png';

function SideBar() {
  const [role, setRole] = useState(null);

  useEffect(() => {
    // Fetch the user's role from local storage or from an API
    const userRole = localStorage.getItem('userRole');
    setRole(userRole);
  }, []);

  return (
    <div className="col-md-3 left_col">
      <div className="left_col scroll-view">
        <div className="navbar nav_title" style={{ border: 0 }}>
          <NavLink to="/dashboard" className="site_title">
            <img src="img/ukPolice.png" alt="Logo" />
            <p id="pehchaan" className="text-center text-bold">Pehchan</p>
          </NavLink>
        </div>
        <div className="clearfix" />
        <div id="sidebar-menu" className="main_menu_side hidden-print main_menu">
          <div className="menu_section">
            <ul className="nav side-menu">


              {/* Show Users menu only if role is Admin or User */}
              {role === 'User' && (
                <>
                  <li>
                    <NavLink to="/dashboardUser" activeClassName="active">
                      <i className="fa fa-home" /> Dashboard
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/profiles" activeClassName="active">
                      <i className="fa fa-user-circle-o" /> Satyapan
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/settings" activeClassName="active">
                      <i className="fa fa-gear" /> Settings/Profile
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/operatorUser" activeClassName="active">
                      <i className="fa fa-user-circle-o" /> Operator User
                    </NavLink>
                  </li>

                </>

              )}

              {/* show Circle Officer */}
              {role === 'CO' && (
                <>
                  <li>
                    <NavLink to="/dashboardUser" activeClassName="active">
                      <i className="fa fa-home" /> Dashboard
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/profiles" activeClassName="active">
                      <i className="fa fa-user-circle-o" /> Satyapan
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/settings" activeClassName="active">
                      <i className="fa fa-gear" /> Settings/Profile
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/operatorUser" activeClassName="active">
                      <i className="fa fa-user-circle-o" /> Operator User
                    </NavLink>
                  </li>

                </>

              )}

              {/* Show Settings/Profile menu only if role is Admin */}
              {role === 'Admin' && (
                <>
                  <li>
                    <NavLink to="/dashboard" activeClassName="active">
                      <i className="fa fa-home" /> Dashboard
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/users" activeClassName="active">
                      <i className="fa fa-user-circle-o" /> Satyapan
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/settings" activeClassName="active">
                      <i className="fa fa-gear" /> Settings/Profile
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/state" activeClassName="active">
                      <img src={state} className='sidebarImg' alt="" /> State
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/district" activeClassName="active">
                      <img src={district} className='sidebarImg' alt="" /> District
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/thana" activeClassName="active">
                      <img src={thana} className='sidebarImg' alt="" /> Thana
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/operator-user" activeClassName="active">
                      <i className="fa fa-user-circle-o" /> Operator User
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/admin-user" activeClassName="active">
                      <i className="fa fa-address-card" /> Admin User
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
