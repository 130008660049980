import React, { useState } from 'react';
import UserServices from '../../services/UserServices';

const UserStatus = ({ user, page }) => {
  // Initialize the state based on the user's status
  const [isChecked, setIsChecked] = useState(user?.status === 'Verify');

  // Handle checkbox toggle
  const handleToggle = async () => {
    const newStatus = isChecked ? 'UnVerify' : 'Verify';  // Correct the status toggle
    setIsChecked(!isChecked);
    if (page === 'user') {
      try {
        const res = await UserServices.updateUserStatus(user._id, {
          status: newStatus
        });

        if (res.status === 200) { // Check if the response is successful
          alert(res.data.message || 'User status updated successfully'); // Show success message from backend
        } else {
          alert('User status updated successfully'); // Show failure message
        }
      } catch (error) {
        alert('You are not eligible to update verify status of this profile since you are the one who last edited it'); // Show error message
        console.error('An error occurred while updating user status', error);
      }
    }
  };

  return (
    <div className="check-box">
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleToggle}
      />
    </div>
  );
};

export default UserStatus;



// import React, { useState } from 'react';
// import UserServices from '../../services/UserServices';

// const UserStatus = ({ user, page }) => {
//   // Initialize the state based on the user's status
//   const [isChecked, setIsChecked] = useState(user?.status === 'Verify');

//   // Handle checkbox toggle
//   const handleToggle = async () => {
//     const newStatus = isChecked ? 'UnVerify' : 'Verify';  // Correct the status toggle
//     setIsChecked(!isChecked);
//     if (page === 'user') {
//       try {
//         const res = await UserServices.updateUserStatus(user._id, {
//           status: newStatus
//         });

//         if (res.status === 200) { // Check if the response is successful
          
//         } else {
//           console.error('Failed to update user status');
//         }
//       } catch (error) {
//         console.error('An error occurred while updating user status', error);
//       }
//     }
//   };

//   return (
//     <div className="check-box">
//       <input
//         type="checkbox"
//         checked={isChecked}
//         onChange={handleToggle}
//       />
//     </div>
//   );
// };

// export default UserStatus;
