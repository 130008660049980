import requests from "./httpService";

const ProductServices = {

    AddProduct : async (body) =>{
        return requests.post(`/product`,body);
    },

    AddCategory : async (body) =>{
        return requests.post(`/category`,body);
    },

    getAllCategory : async () =>{
        return requests.get(`/category`);
    },
    UpdateCategory : async (id,body) =>{
        return requests.put(`/category/${id}`,body);
    },
    deleteCategory : async (id) =>{
        return requests.delete(`/category/${id}`);
    },

    getAllProduct : async () =>{
        return requests.get(`/product`);
    },

    updateProduct : async (id,body) =>{
        return requests.put(`/product/${id}`,body);
    },
    deleteProduct : async (id) =>{
        return requests.delete(`/product/${id}`);
    }
}

export default ProductServices;