import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminServices from '../../services/AdminServices';


function Login({ setIsAuthenticated, setRole }) {
  const [mobileOrEmail, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = await AdminServices.login({ mobileOrEmail: mobileOrEmail, password: password });
    if (data.status === true) {
      localStorage.setItem('authToken', data?.token);
      localStorage.setItem('userRole', data?.data?.role);
      localStorage.setItem('name', data?.data?.name);
      localStorage.setItem('image', data?.data?.image);
      localStorage.setItem('circleName', data?.data?.circleName);
      localStorage.setItem('policeStation', data?.data?.policeStation?.name || '');
      setIsAuthenticated(true);
      setRole('User');
      const role = data?.data?.role;
      if (role === 'User' || role === 'CO') {
        navigate('/dashboardUser');
      } else {
        navigate('/dashboard');
      }
    } else {
      setError("*Invalid Credentials");
    }
  };

  return (
    <div className="login-section">
      <div className="login-section-inner">
        <div className="row">
          <div className="col-md-6 pe-0">
            <div className="login-section-left">
              <h1>Login</h1>
              <p>Welcome to the Admin Panel!</p>
              <form className="user-login" >
                <div className="input-field mb-3">
                  <label htmlFor="userEmail" className="form-label">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="userEmail"
                    placeholder="example@example.com"
                    value={mobileOrEmail}
                    onChange={(e) => setEmail(e.target.value)}
                  />

                </div>
                <div className="input-field mb-3">
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <div className="password-container" style={{ position: 'relative' }}>
                    <input
                      type={showPassword ? 'text' : 'password'}
                      className="form-control"
                      id="password"
                      placeholder="Enter Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span
                      className="toggle-password"
                      onClick={() => setShowPassword((prev) => !prev)}
                      style={{
                        position: 'absolute',
                        top: '50%',
                        right: '10px',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                        color: '#000',
                      }}
                    >
                      {showPassword ? (
                        <i className="fa fa-eye-slash"></i>
                      ) : (
                        <i className="fa fa-eye"></i>
                      )}
                    </span>
                  </div>
                </div>
                <h6 style={{ color: 'red' }}>{error}</h6>
                <div className="input-field mb-3">
                  <input
                    onClick={handleSubmit}
                    type="submit"
                    className="form-control"
                    id="login-btn"
                    value="Login"
                  />
                </div>
              </form>
              {/* <p>© 2024 Software Made by <a href="https://truevalueinfosoft.com/">True Value Infosoft</a>.</p> */}
              <h6 className='text-center'>Version 1.0.0</h6>

            </div>
          </div>
          <div className="col-md-6 ps-0">
            <div className="login-section-right">
              <div className="logo-field">
                <img src="img/ukPolice.png" alt="Logo" />
                <h2 id='pehchaan' className='text-center h2 text-bold'>Pehchan</h2>
              </div>
              {/* <div className="light-field px-4">
                <img src="img/cuate.svg" alt="Illustration" />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
