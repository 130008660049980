import requests from "./httpService";

const PermissionServices = {

    getPermission: async () =>{
        return requests.get(`/permission`);
    },
    savePermission: async (body) =>{ 
        return requests.post(`/permission`,body);
    },


}
export default PermissionServices;