import React from 'react';

function ViewUser({ user, closeModal }) {
  if (!user) return null;

  function formatDateTime(isoString) {
    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return { date: formattedDate, time: formattedTime };
  }

  const url = 'uploads/';
  const { date, time } = formatDateTime(user.createdAt);

  // Function to handle print
  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="modal fade viewbox edit-box show d-block" id="manufacturerModal" tabIndex={-1} aria-labelledby="manufacturerModal" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="manufacturerModal">Details</h5>
            <button type="button" className="btn-close" onClick={closeModal} aria-label="Close"></button>
          </div>
          <div className="modal-body px-5">
            <div className="d-box ">
              <div className="d-flex justify-content-end align-items-center">
                <img src={user?.image ? `${process.env.REACT_APP_URL}${user?.image}` : 'img/profile-img.png'} alt="" style={{ height: '90px', width: '90px', objectFit: 'contain', marginRight: '100px', marginBottom: '20px' }} />
              </div>
              <ul className="d-flex list-unstyled flex-wrap justify-content-between">
                <li><span>Name: </span>{user.name}</li>
                <li><span>Age: </span>{user.age}</li>
                <li><span>Father's Name: </span>{user.fathersName}</li>
                <li><span>Mobile No: </span>{user.mobileNo}</li>
                <li><span>Gender: </span>{user.gender}</li>
                <li ><span>Aadhar No.: </span>{user.aadharNo}</li>
              </ul>

              <h6><span>Present Address:</span></h6>
              <ul className="d-flex list-unstyled flex-wrap justify-content-between">
                <li><span>Mohalla:</span> {user?.presentAddress?.mohalla}</li>
                <li><span>Ward:</span> {user?.presentAddress?.ward}</li>
                {/* <li><span>Village:</span> {user?.presentAddress?.village}</li> */}
                <li><span>Police Station:</span> {user?.presentPoliceStation?.name}</li>
                <li><span>District:</span> {user?.presentDistrict?.name}</li>
                <li><span>State:</span> {user?.presentState?.name}</li>
                <li><span>Pincode:</span> {user?.presentAddress?.pinCode}</li>
              </ul>

              <h6><span>Permanent Address:</span></h6>
              <ul className="d-flex list-unstyled flex-wrap justify-content-between">
                <li><span>Mohalla:</span> {user?.permanentAddress?.mohalla}</li>
                <li><span>Ward:</span> {user?.permanentAddress?.ward}</li>
                {/* <li><span>Village:</span> {user?.permanentAddress?.village}</li> */}
                <li><span>Police Station:</span> {user?.permanentPoliceStation?.name}</li>
                <li><span>District:</span> {user?.permanentDistrict?.name}</li>
                <li><span>State:</span> {user?.permanentState?.name}</li>
                <li><span>Pincode:</span> {user?.permanentAddress?.pinCode}</li>
              </ul>

              <ul className="d-flex list-unstyled flex-wrap justify-content-between">
                <li><span>Profession:</span> {user?.profession?.profession}</li>
                <li><span>Name of Shop:</span> {user?.profession?.nameOfShop}</li>
                <li><span>Owner Name:</span> {user?.profession?.ownerName}</li>
                <li><span>Address:</span> {user?.profession?.address}</li>
                <li><span>Mobile No:</span> {user?.profession?.mobileNo}</li>
                <li><span>Vehicle No:</span> {user?.profession?.vehicleNo}</li>
              </ul>

              <h6><span>Family Members:</span></h6>
              <ul className="list-unstyled">
                {user.familyMembers?.map((member) => (
                  <li key={member._id}>
                    <ul className="d-flex list-unstyled flex-wrap justify-content-between">
                      <li><span>Name:</span> {member.name}</li>
                      <li><span>Relation:</span> {member.relation}</li>
                      <li><span>Age:</span> {member.age}</li>
                      <li><span>Aadhar No:</span> {member.aadhar || 'N/A'}</li>
                      <li><span>Mobile No:</span> {member.mobileNo || 'N/A'}</li>
                    </ul>
                  </li>
                ))}
              </ul>

              <ul className="d-flex list-unstyled flex-wrap justify-content-between">
              <li>
                  <span>Verification Id:</span>
                  {user?.presentAddress?.policeStation?.code && user?.verificationId && user?.presentAddress?.district?.code
                    ? `${user.presentAddress.policeStation.code}${user.verificationId}${user.presentAddress.district.code}`
                    : user?.presentAddress?.policeStation?.code || user?.verificationId || user?.presentAddress?.district?.code
                      ? `${user.presentAddress.policeStation?.code || ''}${user.verificationId || ''}${user.presentAddress.district?.code || ''}`.trim()
                      : 'N/A'}
                </li>
              </ul>

              <ul className="d-flex list-unstyled flex-wrap justify-content-between">
                <li><span>ID Type:</span> {user?.idType}</li>
              </ul>

              {user.userType !== 'customer' && (
                <div className="mt-5 d-box-grp">
                  <a href={process.env.REACT_APP_URL + user?.idPhoto?.front} target='_blank' rel="noopener noreferrer">                 
                  <img src={user?.idPhoto?.front ? `${process.env.REACT_APP_URL}${user?.idPhoto?.front}` : 'img/profile-img.png'} alt="" style={{ height: '200px', width: '200px', objectFit: 'contain', marginRight: '100px', marginBottom: '20px' }} />
                  </a>
                  <a href={process.env.REACT_APP_URL + user?.idPhoto?.back} target='_blank' rel="noopener noreferrer">
                  <img src={user?.idPhoto?.back ? `${process.env.REACT_APP_URL}${user?.idPhoto?.back}` : 'img/profile-img.png'} alt="" style={{ height: '200px', width: '200px', objectFit: 'contain', marginRight: '100px', marginBottom: '20px' }} />
                  </a>
                
                  {/* <a href={process.env.REACT_APP_URL + user?.signatureFinger} target='_blank' rel="noopener noreferrer">
                    <button className="document-update-btn"><img src="img/pdf.svg" alt="PDF" /> Signature</button>
                  </a> */}
                </div>
              )}
            </div>
            {/* Print Button */}
            <div className="text-center mt-4">
              <button className="btn btn-primary" onClick={handlePrint}>Print</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewUser;
