import requests from "./httpService";

const RequestServies = {

    getAllHelp : async () =>{
        return requests.get(`/help`);
    },
    updateHelp : async (id,body) =>{
        console.log(body)
        return requests.patch(`/help/${id}`,body);
    }  ,
    deleteHelp : async (id) =>{
        return requests.delete(`/help/${id}`);
    } ,

    getAllEnquiry : async () =>{
        return requests.get(`/enquiry`);
    },
    deleteEnquiry : async (id) =>{
        return requests.delete(`/enquiry/${id}`);
    },
    updateEnquiry : async (id,body) =>{
        console.log(body)
        return requests.patch(`/enquiry/${id}`,body);
    },
    getAdevertis : async () =>{
        return requests.get(`/advertise`);
    },
    deleteAdevertis : async (id) =>{
        return requests.delete(`/advertise/${id}`);
    },
    updateAdevertis : async (id,body) =>{
        return requests.patch(`/advertise/${id}`,body);
    },

    getAllBenifit : async () =>{
         return requests.get(`/benifit`);
    },
    updateBenifit : async (id,body) =>{
        return requests.patch(`/benifit/${id}`,body);
   },


}

export default RequestServies;