import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useAsync from '../hooks/useAsync';
import StateServices from '../services/StateServices.js';
import UpdateState from './Update Model/UpdateState.js';
import DeleteButton from './Button/DeleteButton';
import Modal from 'react-modal';
// import TogalButtonForStatus from './TogelButton/TogalButtonForStatus';

Modal.setAppElement('#root');

function State() {
  const [states, setStates] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeIndex, setActiveIndex] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState(null);
  
  const { data, error, isLoading, run } = useAsync(StateServices.getState);

  const handleEditDetails = (state) => {
    setSelectedEdit(state);
    setIsEditModalOpen(true);
    toggleActionMenu(null);
  };

  const handleDelete = (state) => {
    setSelectedEdit(state);
    setIsDeleteModalOpen(true);
    toggleActionMenu(null);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedEdit(null);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedEdit(null);
  };

  const toggleActionMenu = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  useEffect(() => {
    if (data?.data) {
      setStates(data.data);
    }
  }, [data]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredStates = states.filter((state) =>
    state.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="right_col" role="main">
      <div className="title-box">
        <h2>State List <span className="badge bg-orange">{filteredStates.length}</span></h2>
        <div className="container-box-top-header-right">
          <Link className="round-add-btn" to="/add-state"><img src="img/plus.svg" alt="Add" /></Link>
        </div>
      </div>
      <div className="container-box px-0">
        <div className="container-box-top-header px-4">
          <div className="container-box-top-header-left-2">
            <input type="search" name="search" placeholder="Search" value={searchTerm} onChange={handleSearchChange} />
            <button className="search-btn">Search</button>
          </div>
        </div>
        <div className="container-box-inner">
          <table id="example" className="table table-striped" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>#</th>
                <th>States Name</th>
                {/* <th>Status</th> */}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredStates.map((state, index) => (
                <tr key={state._id}>
                  <td>{index + 1}</td>
                  <td>{state?.name}</td>
                  {/* <td className="status-toggle">
                    <div className="check-box">
                      <TogalButtonForStatus data={state} page="state" onSuccess={run} />
                    </div>
                  </td> */}
                  <td>
                    <div className="action_toggle_outer">
                      <button
                        className="action_toggle"
                        onClick={() => toggleActionMenu(index)}
                      >
                        <i className="fa fa-ellipsis-v" />
                      </button>
                      {activeIndex === index && (
                        <div className="action_toggle_content product-edit show d-block">
                          <ul className="list-unstyled">
                            <li><button className="close_toggle" onClick={() => toggleActionMenu(null)}><i className="fa fa-close" /></button></li>
                            <li><button className="edit" onClick={() => handleEditDetails(state)}>Edit</button></li>
                            <li><button className="reject" onClick={() => handleDelete(state)}>Delete</button></li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        isOpen={isEditModalOpen}
        onRequestClose={closeEditModal}
        contentLabel="Edit State"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <UpdateState selectedData={selectedEdit} closeModal={closeEditModal} onSuccess={run} />
      </Modal>
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={closeDeleteModal}
        contentLabel="Delete Confirmation"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <DeleteButton data={selectedEdit} page="state" closeModal={closeDeleteModal} onSuccess={run} />
      </Modal>
    </div>
  );
}

export default State;
