import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import StateServices from '../../services/StateServices.js';
import Select from 'react-select';

function UpdateDistrict({ selectedData, closeModal, onSuccess }) {
    const [formValues, setFormValues] = useState({
        name: '',
        district: '',
        code: '',
        email: '',
        circleName:''
    });
    const [districts, setDistricts] = useState([]);

    useEffect(() => {
        const fetchDistricts = async () => {
            try {
                const res = await StateServices.getAllDistrict();
                if (res.status === true) {
                    const districtOptions = res.data.map(district => ({
                        label: district.name,
                        value: district._id
                    }));
                    setDistricts(districtOptions);
                } else {
                    alert('Failed to fetch district');
                }
            } catch (error) {
                console.error('Failed to fetch district', error);
                alert('Failed to fetch district');
            }
        };
        fetchDistricts();
    }, []);

    useEffect(() => {
        if (selectedData) {
            setFormValues({
                name: selectedData.name || '',
                district: districts.find(d => d.value === selectedData.district._id) || '', // Adjust this line to find the selected district
                code: selectedData.code || '',
                email: selectedData.email || '',
                circleName:selectedData.circleName || '',
            });
        }
    }, [selectedData, districts]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    };

    const handleDistrictChange = (selectedOption) => {
        setFormValues({
            ...formValues,
            district: selectedOption
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const res = await StateServices.updateThana(selectedData._id, {
                name: formValues.name,
                district: formValues.district.value, // Send the district ID to the backend
                code: formValues.code,
                email: formValues.email,
                circleName:formValues.circleName,
            });
            if (res.status === true) {
                alert('Thana updated successfully');
                onSuccess();
                closeModal();
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            console.error('Failed to update thana', error);
            alert(error.response?.data?.message || 'Failed to update thana');
        }
    };

    return (
        <div className="modal fade edit-box show d-block" id="editModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Edit Thana</h5>
                        <button type="button" className="btn-close" onClick={closeModal} aria-label="Close" />
                    </div>
                    <div className="modal-body px-5 pb-0">
                        <div className="container-box px-5">
                            <div className="container-box-inner">
                                <div className="page-details">
                                    <form onSubmit={handleSubmit}>
                                        <div className="input-field">
                                            <label>District Name*</label>
                                            <Select
                                                name="district"
                                                className="form-control"
                                                value={formValues.district}
                                                onChange={handleDistrictChange}
                                                options={districts}
                                                placeholder="Select a district"
                                                isSearchable
                                                required
                                            />
                                        </div>

                                        <div className="input-field">
                                            <label>Thana Name*</label>
                                            <input
                                                type="text"
                                                name="name"
                                                className="form-control"
                                                value={formValues.name}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </div>
                                        <div className="input-field">
                                            <label>Thana Code*</label>
                                            <input
                                                type="text"
                                                name="code"
                                                className="form-control"
                                                value={formValues.code}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </div>
                                        <div className="input-field">
                                            <label>Thana Email</label>
                                            <input
                                                type="text"
                                                name="email"
                                                className="form-control"
                                                value={formValues.email}
                                                onChange={handleInputChange}
                                               
                                            />
                                        </div>
                                        <div className="input-field">
                                            <label>Circle Name</label>
                                            <input
                                                type="text"
                                                name="circleName"
                                                className="form-control"
                                                value={formValues.circleName}
                                                onChange={handleInputChange}
                                              
                                            />
                                        </div>
                                        <button className="submit-green-btn" type="submit">
                                            SUBMIT
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

UpdateDistrict.propTypes = {
    selectedData: PropTypes.object,
    closeModal: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default UpdateDistrict;
