import React, { useEffect, useState } from 'react';
import useAsync from '../hooks/useAsync';
import UserServices from '../services/UserServices';
import AdminServices from '../services/AdminServices';
import ViewUser from './View Model/viewThanaUser';
import Modal from 'react-modal';
import UserStatus from './TogelButton/UserStatus';
import UpdateProfile from './Update Model/UpdateThanaUser';
import ReactPaginate from 'react-paginate';
import * as XLSX from 'xlsx';

Modal.setAppElement('#root');

function ThanaUser() {
    const { data, error, isLoading, run } = useAsync(UserServices.getcircleThanaProfiles);
    // console.log(data);
    const [loggedInUser, setLoggedInUser] = useState(null); // Store logged-in user data
    const [currentPage, setCurrentPage] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [activeIndex, setActiveIndex] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedEdit, setSelectedEdit] = useState(null);
    const sanitizedQuery = searchQuery.trim();
    // Fetch logged-in user data
    useEffect(() => {
        const fetchLoggedInUser = async () => {
            try {
                const response = await AdminServices.getMyProfile();
                setLoggedInUser(response); // Set logged-in user data
            } catch (error) {
                console.error('Error fetching logged-in user:', error);
            }
        };
        fetchLoggedInUser();
    }, []);

    const handleEditDetails = (thana) => {
        setSelectedEdit(thana);
        setIsEditModalOpen(true);
        toggleActionMenu(null);
    };
    const closeEditModal = () => {
        setIsEditModalOpen(false);
        setSelectedEdit(null);
    };

    const handleViewDetails = (user) => {
        setSelectedUser(user);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        // setSelectedUser(null);
    };

    const handlePageClick = (data) => {
        setCurrentPage(data.selected + 1);
    };

    const toggleActionMenu = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        return `${day}-${month}-${year}`;
    };

    const usersPerPage = 20;
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;

    const filteredUsers = data?.data?.filter(user => {
        return (
            user?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user?.user?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            String(user?.mobileNo || "").toLowerCase().includes(searchQuery.toLowerCase()) ||
            String(user?.verificationId || "").includes(searchQuery) ||
            user?.presentAddress?.policeStation?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user?.presentAddress?.state?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user?.presentAddress?.district?.name?.toLowerCase().includes(searchQuery.toLowerCase())
        );
    });
    
    

    const currentUsers = filteredUsers?.slice(indexOfFirstUser, indexOfLastUser);

    const generateExcel = () => {
        const filteredData = filteredUsers?.map((user) => ({
            userType: user?.user?.name,
            userMobileNo: user?.user?.mobileNo,
            name: user?.name,
            image: user?.image,
            mobileNo: user?.mobileNo,
            state: user?.permanentAddress?.state?.name,
            district: user?.permanentAddress?.district?.name,
            policeStation: user?.permanentAddress?.policeStation?.name, // Assuming you want to display the name
            age: user?.age,
            status: user?.status,
        }));

        const ws = XLSX.utils.json_to_sheet(filteredData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Users');

        XLSX.writeFile(wb, 'user_data.xlsx');
    };

    // useEffect(() => {
    //     if (data?.users) {
    //         run(UserServices.getAllUser);
    //     }
    // }, [data]);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    return (
        <div className="right_col" role="main">
            <div className="title-box">
                <h2>Satyapan <span className="badge bg-warning">{filteredUsers?.length || 0}</span></h2>
            </div>
            <div className="sub-title-box">
                {/* <div className="sub-title-box-left">
                    <p>Satyapan List</p>
                </div> */}
                <div className="sub-title-box-right">
                    <button className="excel-btn" onClick={generateExcel}>
                        <img src="img/excel.svg" alt="Excel" />Download
                    </button>
                </div>
            </div>
            <div className="container-box px-0 user-manager">
                <div className="container-box-top-header px-4">
                    <div className="container-box-top-header-left-2">
                        <input
                            type="search"
                            name="search"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                        <button className="search-btn">Search</button>
                    </div>
                </div>
                <div className="container-box-inner">
                    <table id="example" className="table table-striped" style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>User Name</th>
                                <th>User Mobile No.</th>
                                <th>Name</th>
                                <th>Image</th>
                                <th>Mobile No.</th>
                                <th>State</th>
                                <th>District</th>
                                <th>Police Station</th>
                                <th>Verification ID</th>
                                <th>Register Date</th>
                                <th>Status Label</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentUsers?.map((user, index) => (
                                <tr key={user?._id}>
                                    <td>{index + 1 + (currentPage - 1) * usersPerPage}</td>
                                    <td><span className="usertype">{user?.user?.name}</span></td>
                                    <td>{user?.user?.mobileNo}</td>
                                    <td>{user?.name}</td>
                                    <td>
                                        <img
                                            src={user?.image ? `${process.env.REACT_APP_URL}${user.image}` : 'img/profile-img.png'}
                                            alt=""
                                            style={{ height: '50px', width: '50px', objectFit: 'contain', borderRadius: '50%' }}
                                        />
                                    </td>
                                    <td>{user?.mobileNo}</td>
                                    <td>{user?.presentAddress?.state?.name || 'N/A'}</td>
                                    <td>{user?.presentAddress?.district?.name || 'N/A'}</td>
                                    <td>{user?.presentAddress?.policeStation?.name || 'N/A'}</td>
                                    <td>
                                        {user?.presentAddress?.policeStation?.code && user?.verificationId && user?.presentAddress?.district?.code
                                            ? `${user.presentAddress.policeStation.code} ${user.verificationId} ${user.presentAddress.district.code}`
                                            : user?.presentAddress?.policeStation?.code || user?.verificationId || user?.presentAddress?.district?.code
                                                ? user.presentAddress.policeStation.code || user.verificationId || user?.presentAddress?.district?.code
                                                : 'N/A'}
                                    </td>
                                    <td>{formatDate(user?.createdAt)}</td>

                                    <td>
                                        <button
                                            className="view-details-btn"
                                            style={{
                                                backgroundColor: user?.status === 'Verify' ? '#198754' : '#dc3545',
                                                color: 'white'
                                            }}>
                                            {user?.status}
                                        </button>
                                    </td>
                                    <td className="status-toggle">
                                        <UserStatus user={user} page={"user"} onSuccess={run} />
                                    </td>
                                    <td>
                                        <div className="action_toggle_outer">
                                            <button
                                                className="action_toggle"
                                                onClick={() => toggleActionMenu(index)}
                                            >
                                                <i className="fa fa-ellipsis-v" />
                                            </button>
                                            {activeIndex === index && (
                                                <div className="action_toggle_content product-edit show d-block">
                                                    <ul className="list-unstyled">
                                                        <li>
                                                            <button className="close_toggle" onClick={() => toggleActionMenu(null)}>
                                                                <i className="fa fa-close" />
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button className="edit" onClick={() => handleEditDetails(user)}>Edit</button>
                                                        </li>
                                                        <li>
                                                            <button className="view-details-btn" onClick={() => handleViewDetails(user)}>View</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                    {/* <td>
                                        <button className="view-details-btn" onClick={() => handleViewDetails(user)}>
                                            View
                                        </button>
                                    </td> */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="pagination">
                    <ReactPaginate
                        pageCount={Math.ceil((filteredUsers?.length || 0) / usersPerPage)}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        onPageChange={handlePageClick}
                        containerClassName="pagination"
                        activeClassName="active"
                    />
                </div>
            </div>

            <Modal
                isOpen={isEditModalOpen}
                onRequestClose={closeEditModal}
                contentLabel="Edit Category"
                className="modal-content"
                overlayClassName="modal-overlay"
            >
                <UpdateProfile selectedData={selectedEdit} closeModal={closeEditModal} onSuccess={run} />
            </Modal>
            <Modal
                //   isOpen={isEditModalOpen}
                //   onRequestClose={closeEditModal}
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="User Details"
                className="modal-content"
                overlayClassName="modal-overlay"
            >
                <ViewUser user={selectedUser} closeModal={closeModal} onSuccess={run} />
            </Modal>
        </div>
    );
}

export default ThanaUser;
