import React from 'react'

function Footer({ name ,className }) {
  return (
   <footer> 
  
  
  </footer>

  )
}

export default Footer