import React, { useEffect, useState } from 'react'
import useAsync from '../hooks/useAsync'
import UserServices from '../services/UserServices'
import ViewUser from './View Model/viewDoctor';
import Modal from 'react-modal';
import UserStatus from './TogelButton/UserStatus';
import ReactPaginate from 'react-paginate';
import * as XLSX from 'xlsx';
Modal.setAppElement('#root');
function Trainer() {
  const { data, error, isLoading, run } = useAsync(UserServices.getAllTrainer);
  console.log(">>>>>>>>",data);
  const count = data?.users?.length
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const handleViewDetails = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0'); // Get day and pad with leading zero if needed
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get month (add 1 since it's zero-based) and pad with leading zero if needed
    const year = date.getFullYear().toString();//.slice(2); // Get year and take only last two digits

    return `${day}-${month}-${year}`;
  };

  const generateExcel = () => {
    // Map the filtered users to include only the required fields
    const filteredData = data?.data?.map(user => ({
      userType: user.userType,
      name: user.name,
      email: user.email,
      mobileNo: user.mobileNo,
      qualification: user.qualification,
      degree: user.degree,
      adharcard: user.adharcard,
      feeType: user.feeType,
      age: user.age,
      address: user.address,
      wardNo : user.ward,
      block : user.block,
      village: user.village,
      city: user.city,
      district: user.district,      
      state:user.state,     
      rating:user.rating,
      totalEarning:user.totalEarning,
      status:user.status
    }));
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Users');
    XLSX.writeFile(wb, 'user_data.xlsx');
  };

  const usersPerPage = 20;
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  // const currentUsers = data?.data?.slice(indexOfFirstUser, indexOfLastUser);
  const currentUsers = data?.data?.slice().reverse().slice(indexOfFirstUser, indexOfLastUser);
  useEffect(() => {
    if (data?.users) {
      currentUsers(data.users);
    }
  }, [data]);
 
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="right_col" role="main">
      <div className="title-box">
        <h2>Trainer <span className="badge bg-warning">{count}</span></h2>
      </div>
      <div className="sub-title-box">
        <div className="sub-title-box-left">
          <p>Trainer List</p>

        </div>

        <div className="sub-title-box-right">
          <button className="excel-btn" onClick={generateExcel}><img src="img/excel.svg" />Download</button>
        </div>
      </div>
      <div className="container-box px-0 user-manager">
        <div className="container-box-top-header px-4">
          <div className="container-box-top-header-left-2">
            <input type="search" name="search" placeholder="Search" />
            <button className="search-btn">Search</button>
          </div>
        </div>
        <div className="container-box-inner">
          <table id="example" className="table table-striped" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>#</th>
                <th>User Type</th>
                <th>Name</th>
                <th>Imgae</th>
                <th>Mobile No.</th>
                <th>Email</th>
                <th>State</th>
                <th>Address</th>
                <th>Total Earning</th>
                <th>Age</th>
                {/* <th>Payment</th> */}
                <th>Registration Date</th>
                <th>Status</th>
                <th>More Details</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers?.map((user, index) => (
                <tr key={user?._id}>
                  {/* <td>{index+1}</td> */}
                  <td>{index + 1 + (currentPage - 1) * usersPerPage}</td>
                  <td><span className="usertype">{user?.userType}</span></td>
                  <td>{user?.name}</td>
                  <td> <img src={user?.image ? `${process.env.REACT_APP_URL}${user.image}` : 'img/profile-img.png'} alt="" style={{ height: '50px', width: '50px', objectFit: 'contain' }} /></td>
                  <td>{user?.mobileNo}</td>
                  <td className='email'>{user?.email ? user.email : 'N/A'}</td>
                  <td>{user?.state}</td>
                  <td className="address">{user?.address ? user.address : 'N/A'}</td>
                  <td>{user?.totalEarning }</td>
                  <td>{user?.age ? user.age : 'N/A'}</td>
                  {/* <td>{user?.plan?.planName}</td> */}
                  <td>{user?.createdAt ? formatDate(user.createdAt) : '-'}</td>
                  <td className="status-toggle"><UserStatus user={user} onSuccess={run} /></td>
                  <td>
                    <button
                      className="view-details-btn"
                      onClick={() => handleViewDetails(user)}
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))}

            </tbody>
          </table>
        </div>
        {/* Pagination */}
        <div className="pagination">
          <ReactPaginate
            pageCount={Math.ceil(data?.data?.length / usersPerPage)}
            pageRangeDisplayed={3} // Number of page numbers to display
            marginPagesDisplayed={1} // Number of pages to display at the beginning and end
            onPageChange={({ selected }) => setCurrentPage(selected + 1)}
            containerClassName={'pagination'}
            activeClassName={'active'}
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            initialPage={currentPage - 1} // Set initial page
            disableInitialCallback={true} // Prevent initial callback
            pageLinkClassName={'page-link'}
            previousLinkClassName={'page-link'}
            nextLinkClassName={'page-link'}
            pageClassName={'page-item'}
            previousClassName={'page-item'}
            nextClassName={'page-item'}
            disabledClassName={'disabled'}
          />
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="User Details"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <ViewUser user={selectedUser} closeModal={closeModal} />
      </Modal>
    </div>
  )

}

export default Trainer