import requests from "./httpService";

const PetServices = {

    AddPet : async (body) =>{
        return requests.post(`/pet`,body);
    },
    updateAppPolicy : async (id,body) =>{
        return requests.put(`/pet/pets/${id}`,body);
    },

    getPetList : async () =>{
        return requests.get(`/pet`);
    },

    deletePet : async (id) =>{
        return requests.delete(`/pet/pets/${id}`);
    },
    addSellAnimal : async (body) =>{
        return requests.post(`/sellAnimal`,body);
    },
    getSellList : async (body) =>{
        return requests.get(`/sellAnimal`,body);
    },
    updateSellAnimal : async (id,body) =>{
        return requests.put(`/sellAnimal/${id}`,body);
    },
    deleteSellAnimal : async (id) =>{
        return requests.delete(`/sellAnimal/${id}`);
    },

}

export default PetServices;